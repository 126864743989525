<template>
	<a target="_blank" rel="noopener noreferrer" :href="getBookingUrl({ ...props })">
		<slot />
	</a>
</template>

<script setup>
const { locale } = useI18n();

const bookingEngineUrl = 'https://ibev5.hotels-online-buchen.de/ibe/a2schopsdorf/';

const props = defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

const dateToString = (date) => {
	if (typeof date === 'string') {
		return date;
	}

	return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
};

const getBookingUrl = (options) => {
	let url = new URL(bookingEngineUrl);

	if (!options.language) {
		options.language = locale.value;
	}

	if (options.arrival) {
		url = url + 'quickcheck/' + dateToString(options.arrival) + '/';
	}

	if (options.departure) {
		url = url + dateToString(options.departure) + '/2/0/0/0/0/0/0/0/0/0/0/0';
	}

	return url.href;
};
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
